@import url(https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,700);

.Logo__logoLink___3398n {
  display: flex;
  justify-content: center;
  text-decoration: none; }
  .Logo__logoLink___3398n .Logo__logo___35NyS {
    background-position: 0px 0px;
    width: 157px;
    height: 70px;
    font-size: 0; }
    .Logo__logoLink___3398n .Logo__logo___35NyS img {
      max-width: 100%;
      max-height: 100%;
      width: auto; }
  .Logo__logoLink___3398n.Logo__secundary___2vrk_ .Logo__logo___35NyS {
    background-position: 0px -73px; }

.Login__paper___3iqTM {
  margin: 12vh 0;
  display: flex;
  flex-direction: column;
  align-items: center; }

.MenuBar__menuBar___320Ai a {
  color: #757575;
  text-decoration: none !important; }

.MenuBar__menuBar___320Ai .MenuBar__collapse___2KmRs {
  background: #eee; }

.Calculator__listCoins___2FrdW {
  display: block;
  width: 100%; }
  .Calculator__listCoins___2FrdW .Calculator__paper___3H9vy {
    padding: 15px 0 0; }
  .Calculator__listCoins___2FrdW .Calculator__item___3hUKA {
    padding: 5px 20px;
    width: 100%;
    cursor: pointer; }
    .Calculator__listCoins___2FrdW .Calculator__item___3hUKA:hover {
      background: #eee; }
    .Calculator__listCoins___2FrdW .Calculator__item___3hUKA .Calculator__name___2chHX {
      border-radius: 40px;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #3f51b5;
      font-size: 13px;
      color: #fff;
      font-weight: 600; }
    .Calculator__listCoins___2FrdW .Calculator__item___3hUKA td {
      font-size: 15px;
      color: #7c7a88; }
  .Calculator__listCoins___2FrdW [class*=MuiTableRow-hover]:hover {
    background-color: rgba(145, 148, 161, 0.2) !important; }
  .Calculator__listCoins___2FrdW [class*=Mui-selected] {
    background-color: rgba(63, 81, 181, 0.8) !important; }
    .Calculator__listCoins___2FrdW [class*=Mui-selected] .Calculator__name___2chHX {
      color: #3f51b5;
      background: #fff; }
    .Calculator__listCoins___2FrdW [class*=Mui-selected] td {
      color: #fff; }
    .Calculator__listCoins___2FrdW [class*=Mui-selected]:hover {
      background-color: rgba(63, 81, 181, 0.8) !important; }

.Calculator__main___PnmKl {
  width: 100%;
  display: block; }

.Calculator__paper___3H9vy {
  padding: 20px 0; }

.Calculator__formControl___3IxyJ {
  min-width: 200px;
  margin-right: 15px !important; }

.Calculator__alignRigth___1waD1 {
  text-align: right; }

.Calculator__block___3Wzx6 {
  margin: 0px; }

.Calculator__actionsContainer___3xSXX {
  padding: 25px; }

.Calculator__listing___1XkPa .Calculator__cod___2e7d5 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: #3f51b5;
  border-radius: 40px;
  color: #fff;
  margin: 0 auto;
  font-size: 14px;
  font-weight: 600;
  padding: 10px; }

.Calculator__listing___1XkPa [class*='MuiTableRow-root'] [class*='MuiTableCell-root'] {
  padding: 10px;
  max-height: 50px; }

.Calculator__listing___1XkPa table .Calculator__p___3BsG0 {
  margin: 0;
  padding: 0; }

.Calculator__listing___1XkPa .Calculator__client___1c3tX {
  display: flex;
  flex-direction: column; }
  .Calculator__listing___1XkPa .Calculator__client___1c3tX .Calculator__p___3BsG0 {
    color: #3f51b5;
    font-size: 15px; }
  .Calculator__listing___1XkPa .Calculator__client___1c3tX .Calculator__span___1euz3 {
    color: #707070;
    display: block;
    font-size: 13px; }

.Calculator__listing___1XkPa .Calculator__coin___3_b7L .Calculator__span___1euz3 {
  font-size: 15px;
  color: #3f51b5; }

.Calculator__listing___1XkPa .Calculator__coin___3_b7L .Calculator__paymentOk___1IP_E {
  color: #45a361; }

.Calculator__listing___1XkPa .Calculator__send___1hFLJ .Calculator__span___1euz3 {
  font-size: 15px;
  color: #3f51b5; }

.Calculator__listing___1XkPa .Calculator__send___1hFLJ .Calculator__deliveryOk___28iak {
  color: #45a361; }

.HomePage__wellcome___Pt2cM {
  padding: 15px;
  display: flex;
  width: 100%; }
  .HomePage__wellcome___Pt2cM .HomePage__image___WpEAU {
    border-radius: 100px;
    height: 100px;
    width: 100px;
    overflow: hidden;
    margin-right: 20px; }
  .HomePage__wellcome___Pt2cM .HomePage__content___3JMdE .HomePage__h1___2wW6f {
    font-size: 42px;
    text-transform: capitalize;
    font-weight: 200;
    color: #808290;
    margin: 10px 0 0; }
  .HomePage__wellcome___Pt2cM .HomePage__content___3JMdE .HomePage__p___ow_1K {
    font-size: 18px;
    color: #808290;
    margin-left: 2px; }

.extract__main___2becp {
  width: 100%; }

body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif; }

img {
  width: 100%; }

